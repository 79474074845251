<template>
  <div class="full-viewport">

    <canvas ref="experience" class="full-viewport"></canvas>

    <div
      v-if="showSafeArea"
      :class="safeAreaOptions[toggleSafeAreaType]"
      class="safe-area"
    ></div>

    <ModelLoadProgress
      :loading="loadingScene || loadingSingleModel"
      :hide-overlay="loadingSingleModel"
      :loading-list="loadingModelList"
    />

    <v-expansion-panels
      v-model="panel"
      class="expansion-panels"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('object_adjustments') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-select
            :label="$t('selected')"
            :items="objectsList"
            v-model="selectedObjectIndex"
            dense
            outlined
          ></v-select>

          <span class="text-center d-flex align-center">
            <v-btn-toggle
              v-model="toggleTransformMode"
              mandatory
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!selectedObject"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-cursor-move</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('move_object') }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!selectedObject"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-rotate-360</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('rotate_object') }}</span>
              </v-tooltip>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!selectedObject"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-resize</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('resize_object') }}</span>
              </v-tooltip>
            </v-btn-toggle>

            <v-spacer></v-spacer>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="!selectedObject"
                  @click="duplicateObject"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('duplicate_object') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="!selectedObject"
                  @click="deleteObject"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('delete_object') }}</span>
            </v-tooltip>
          </span>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('camera_settings') }}</v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-slider
            v-model="cameraFov"
            :label="$t('camera_fov')"
            max="90"
            min="10"
          ></v-slider>

          <v-checkbox
            v-model="showSafeArea"
            :label="$t('show_safe_area')"
            class="m-0"
          ></v-checkbox>

          <span class="text-center d-flex align-center">
            <v-btn-toggle
              v-model="toggleSafeAreaType"
              mandatory
              dense
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!showSafeArea"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-crop-landscape</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('switch_landscape') }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!showSafeArea"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-crop-square</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('switch_square') }}</span>
              </v-tooltip>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!showSafeArea"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-crop-portrait</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('switch_portrait') }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </span>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('scene_light') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-slider
            v-model="lightDirection"
            :label="$t('direction')"
            max="360"
            min="-360"
          ></v-slider>
          <v-slider
            v-model="lightStrength"
            :label="$t('strength')"
            max="20"
            min="0"
          ></v-slider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('library') }}</v-expansion-panel-header>
        <v-expansion-panel-content class="library-content">

          <ReferenceLibrary
            :disable-import="loadingSingleModel || loadingScene"
            :jsm-node-id="jsmNodeId"
            @download-link-created="addObjToScene"
          />

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <span class="footer-actions-container d-flex justify-center mb-6">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="closeScreen"
            v-bind="attrs"
            v-on="on"
            class="footer-action"
            color="error"
            fab
            dark
          >
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('close') }}</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="submitScreen"
            v-bind="attrs"
            v-on="on"
            class="footer-action"
            color="success"
            fab
            dark
          >
            <v-icon>mdi-check-bold</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('save_reference') }}</span>
      </v-tooltip>
    </span>

  </div>
</template>

<style scoped>
  canvas {
    display: block;
  }

  .full-viewport {
    width: 100% !important;
    height: 100% !important;
  }

  .expansion-panels {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1%;
    width: 20%;
    z-index: 1;
  }

  .library-content {
    overflow-y: auto;
    max-height: 350px;
  }

  .footer-actions-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  .footer-action {
    margin: 1%;
  }

  .safe-area {
    position: absolute;
    border: 2px dashed var(--v-primary-base);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .safe-area.tall {
    height: 80%;
    aspect-ratio: 9 / 16;
  }

  .safe-area.wide {
    height: 80%;
    aspect-ratio: 16 / 9;
  }

  .safe-area.square {
    height: 80%;
    aspect-ratio: 1 / 1;
  }
</style>

<script>

import { mapState, mapActions } from 'vuex'

import AppActions from '@/store/app/actions-types'
import SyntheticActions from '@/store/content/synthetic/actions-types'

import {
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  GridHelper,
  Color,
  DirectionalLight,
  AmbientLight,
  PCFSoftShadowMap,
} from 'three'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

import ReferenceLibrary from '@/components/content/synthetic/reference_3d/ReferenceLibrary.vue'
import ModelLoadProgress from '@/components/content/synthetic/reference_3d/ModelLoadProgress.vue'

export default {
  name: "Reference3D",

  components: {
    ReferenceLibrary,
    ModelLoadProgress,
  },

  props: {
    jsmNodeId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      loader: new GLTFLoader(),
      scene: new Scene(),
      camera: null,
      renderer: null,
      orbitControls: null,
      gridHelper: null,
      loadingScene: false,
      loadingSingleModel: false,
      loadingModelList: [],
      panel: [],
      objects: [],
      selectedObjectIndex: null,
      directionalLight: null,
      lightDirection: 0,
      lightStrength: 10,
      cameraFov: 50,
      transformControls: null,
      toggleTransformMode: 0,
      transformModes: ["translate", "rotate", "scale"],
      showSafeArea: false,
      toggleSafeAreaType: 0,
      safeAreaOptions: ['wide', 'square', 'tall'],
    }
  },

  watch: {
    selectedObject() {
      this.setupActiveObject()
    },

    toggleTransformMode() {
      this.transformControls.mode = this.transformModes[this.toggleTransformMode]
    },

    lightDirection() {
      this.directionalLight.position.set(this.lightDirection, 10, 10)
    },

    lightStrength() {
      this.directionalLight.intensity = this.lightStrength / 10
    },

    cameraFov() {
      this.camera.fov = this.cameraFov
      this.camera.updateProjectionMatrix()
    },
  },

  computed: {
    ...mapState({
      userToken: state => state.core.auth.token,
    }),

    objectsList() {
      return this.objects.map(object => ({text: object.name, value: object.id}))
    },

    selectedObject() {
      return this.objects.find(object => object.id === this.selectedObjectIndex)
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE,
    ]),

    ...mapActions('content/synthetic', [
      SyntheticActions.GET_SYNTHETIC_MEDIA,
      SyntheticActions.SAVE_SYNTHETIC_MEDIA,
    ]),

    init() {
      this.setupCamera()

      this.setupRenderer()

      this.setupBaseScene()

      this.setupControls()

      this.loadSceneData()
    },

    setupCamera() {
      this.camera = new PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000)
      this.camera.position.set(3, 5, 8)
      this.camera.lookAt(this.scene.position)

      this.scene.add(this.camera)
    },

    setupRenderer() {
      this.renderer = new WebGLRenderer({
        canvas: this.$refs.experience,
        antialias: true
      })

      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(window.innerWidth, window.innerHeight)

      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = PCFSoftShadowMap

      this.renderer.setAnimationLoop(() => {
        this.render()
      })
    },

    render() {
      this.renderer.render(this.scene, this.camera)
    },

    setupBaseScene() {
      this.scene.add(new AmbientLight(0x5A5A5A))

      this.gridHelper = new GridHelper(100, 100)
      this.scene.add(this.gridHelper)

      this.scene.background = new Color(0xbbbbbb)

      this.directionalLight = new DirectionalLight(0xffffff, 1)
      this.directionalLight.castShadow = true
      this.scene.add(this.directionalLight)
    },

    setupControls() {
      this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement)
      this.orbitControls.minDistance = 2
      this.orbitControls.maxDistance = 50

      this.transformControls = new TransformControls(this.camera, this.renderer.domElement)
			this.transformControls.size = 0.75;
      this.transformControls.mode = 'translate';
			this.transformControls.space = 'world';
			this.scene.add(this.transformControls);

      this.transformControls.addEventListener('mouseDown', () => this.orbitControls.enabled = false)
			this.transformControls.addEventListener('mouseUp', () => this.orbitControls.enabled = true)

      window.addEventListener('resize', this.onWindowResizeHandler)
      document.addEventListener('keydown', this.shortcutsHandler, false)
    },

    onWindowResizeHandler() {
      this.camera.aspect = this.renderer.domElement.clientWidth / this.renderer.domElement.clientHeight
			this.camera.updateProjectionMatrix()

			this.renderer.setSize(this.renderer.domElement.clientWidth, this.renderer.domElement.clientHeight)
    },

    shortcutsHandler(e) {
      switch (e.key) {
        case "w":
          this.toggleTransformMode = this.transformModes.indexOf("translate")
          break
        case "e":
          this.toggleTransformMode = this.transformModes.indexOf("rotate")
          break
        case "r":
          this.toggleTransformMode = this.transformModes.indexOf("scale")
          break
      }
    },

    setupActiveObject() {
      this.transformControls.detach()

      if (this.selectedObject) {
        this.transformControls.attach(this.selectedObject)
      }
    },

    duplicateObject() {
      let clonedObj = this.selectedObject.clone()

      clonedObj.url = this.selectedObject.url
      clonedObj.name = this.selectedObject.name
      clonedObj.size = this.selectedObject.size

      this.scene.add(clonedObj)
      this.objects.push(clonedObj)
    },

    deleteObject() {
      this.scene.remove(this.selectedObject)
      this.objects = this.objects.filter(object => object.id !== this.selectedObjectIndex)
      this.selectedObjectIndex = null
    },

    addObjToScene(data) {
      this.loadingSingleModel = true

      this.loader.requestHeader = {token: this.userToken}

      this.loadingModelList = [...[
        {
          name: data.name,
          progress: 0
        }
      ]]

      this.loader.load(data.url,
        (gltf) => {
          let model = gltf.scene

          model.url = data.url
          model.name = data.name
          model.size = data.fileSize

          this.scene.add(model)
          this.objects.push(model)

          this.loadingSingleModel = false
        },
        (xhr) => {
          this.$set(this.loadingModelList[0], 'progress', (xhr.loaded / data.fileSize * 100))
        },
        (error) => {
          this.loadingSingleModel = false

          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('import_model_error', {message: error}))
        }
      )
    },

    closeScreen() {
      this.$emit('close')
    },

    submitScreen() {
      this.generateReferenceRender()

      this.saveSceneData()
    },

    generateReferenceRender() {
      this.transformControls.visible = false
      this.gridHelper.visible = false

      this.renderer.render(this.scene, this.camera)

      const imageData = this.$refs.experience.toDataURL('image/png')

      this.gridHelper.visible = true
      this.transformControls.visible = true

      this.$emit('submit-image', imageData)
    },

    saveSceneData() {
      const sceneData = {
        objects: this.objects.map(object => {
          return {
            name: object.name,
            size: object.size,
            position: object.position,
            rotation: object.rotation,
            scale: object.scale,
            url: object.url
          }
        }),
        ligths: {
          directional: {
            intensity: this.lightStrength,
            direction: this.lightDirection
          }
        },
        camera: {
          position: this.camera.position,
          rotation: this.camera.rotation,
          fov: this.camera.fov
        }
      }

      this[SyntheticActions.SAVE_SYNTHETIC_MEDIA]({nodeId: this.jsmNodeId, params: sceneData})
    },

    async loadSceneData() {
      const response = await this[SyntheticActions.GET_SYNTHETIC_MEDIA](this.jsmNodeId)
      const config = response.config || {}

      this.loader.requestHeader = {token: this.userToken}

      if (config.objects && config.objects.length != 0) {
        this.loadingScene = true

        this.loadingModelList = [... config.objects.map(object => {
          return {
            name: object.name,
            progress: 0
          }
        })]
      }

      let loads = []
      config.objects?.forEach((object, index) => {

        const promise = new Promise((resolve, reject) => {
          this.loader.load(object.url,
            (gltf) => {
              let model = gltf.scene

              model.url = object.url
              model.name = object.name
              model.size = object.size

              model.position.set(
                object.position.x,
                object.position.y,
                object.position.z
              )

              model.scale.set(
                object.scale.x,
                object.scale.y,
                object.scale.z
              )

              model.setRotationFromEuler(object.rotation)
              model.updateMatrix()

              this.scene.add(model)
              this.objects.push(model)

              resolve(model)
            },
            (xhr) => {
              this.$set(this.loadingModelList[index], 'progress', (xhr.loaded / object.size * 100))
            },
            (error) => {
              this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('import_model_error', {message: error}))

              reject(error)
            }
          )
        })

        loads.push(promise)
      })

      Promise.all(loads).finally(() => {
        this.loadingScene = false
      })

      if (config.ligths) {
        this.lightDirection = config.ligths.directional.direction ?? 1
        this.lightStrength = config.ligths.directional.intensity ?? 10
      }

      if (config.camera) {
        this.camera.position.set(
          config.camera.position.x ?? 0,
          config.camera.position.y ?? 0,
          config.camera.position.z ?? 0
        )

        this.camera.setRotationFromEuler(config.camera.rotation)

        this.cameraFov = config.camera.fov ?? 50
      }
    },
  }
}

</script>
