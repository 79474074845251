<template>
  <v-slide-group
    v-model="selectedNumberOfImages"
    style="margin-bottom: 18px"
    mandatory
  >
    <v-slide-item
      v-for="n in maxNumberOfImages"
      :key="n"
      v-slot="{ active, toggle }"
    >
      <v-card
        :class="'ma-2 card-btn' + (active ? ' active' : '')"
        height="36"
        width="52"
        @click="toggle"
        outlined
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
        {{ n }}
        </v-row>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<style scoped>
  .card-btn {
    border: 1px solid #707070;
    background-color: #7070706B;
    padding-top: 18px;
  }

  .card-btn.active {
    border: 1px solid var(--v-primary-darken3);
    background-color: var(--v-primary-lighten1);
    color: var(--v-secondary-base);
  }
</style>

<script>

import { mapState } from 'vuex'

export default {
  name: 'SyntheticMediaNumberOfImagesSelect',

  data() {
    return {
      selectedNumberOfImages: 0
    }
  },

  computed: {
    ...mapState({
      maxNumberOfImages: state => state.content.synthetic.maxNumberOfImages
    })
  },

  watch: {
    selectedNumberOfImages() {
      this.$emit('change', this.selectedNumberOfImages + 1)
    }
  },

  methods: {
    setValue(value) {
      this.selectedNumberOfImages = value - 1
    }
  }
}

</script>
