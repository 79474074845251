<template>
  <v-form
    ref="form"
    @submit.prevent="generateTxt2Img"
  >
    <SyntheticMediaBarTitle
      :title="$t('Filter style')"
      :helpText="$t('filter_style_tooltip')"
    />

    <SyntheticMediaFilterSelect
      @change="filterChanged"
      :styles="styles"
      ref="filterSelect"
    />

    <SyntheticMediaBarTitle
      :title="$t('Prompt')"
      :helpText="$t('prompt_tooltip')"
    />

    <v-textarea
      :label="$t('What do you want to see?')"
      :rules="[v => !!v || $t('form_rules.mandatory')]"
      v-model="prompt"
      rows="2"
      no-resize
      outlined
    ></v-textarea>

    <SyntheticMediaBarTitle
      :title="$t('Negative prompt')"
      :helpText="$t('negative_prompt_tooltip')"
    />

    <v-textarea
      :label="$t('What do you want to avoid?')"
      v-model="negativePrompt"
      rows="2"
      no-resize
      outlined
    ></v-textarea>

    <SyntheticMediaBarTitle
      :title="$t('Number of images')"
      :helpText="$t('number_of_images_tooltip')"
    />

    <SyntheticMediaNumberOfImagesSelect
      @change="numberOfImagesChanged"
      ref="numOfImgSelect"
    />

    <SyntheticMediaBarTitle
      :title="$t('Aspect ratio')"
      :helpText="$t('aspect_ratio_tooltip')"
    />

    <SyntheticMediaAspectRatioSelect
      @change="aspectRatioChanged"
      ref="aspectRatioSelect"
    />

    <v-tooltip
      right
      nudge-top="13"
      open-delay="600"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="initializer"
          :label="$t('Initializer')"
          outlined
          dense
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <span>{{$t('initializer_tooltip')}}</span>
    </v-tooltip>

    <SyntheticMediaBarTitle
      :title="$t('style_reference')"
      :helpText="$t('style_reference_tooltip')"
    />

    <SyntheticMediaStyleReference
      ref="styleReference"
      class="mb-4"
      @changeStyleReferences="styleImagesReferenceChanged"
      @changeImagesStrength="styleImagesStrengthChanged"
      @changeGlobalImageStrength="styleGlobalImageStrengthChanged"
      @changeGlobalStyleLiberty="globalStyleLibertyChanged"
    />

    <SyntheticMediaBarTitle
      :title="$t('image_reference')"
      :helpText="$t('image_reference_tooltip')"
    />

    <SyntheticMediaImageReference
      ref="imageReferenceSelect"
      class="mb-4"
      @changeImageReference="imageReferenceChanged"
      @changeImageStrength="imageStrengthChanged"
      @changeReferenceProcess="referenceProcessChanged"
    />

    <v-btn
      :loading="loading"
      color="primary"
      type="submit"
      block
      raised
      large
    >
      <template v-slot:loader>
        {{ $t(generationMessage) || $t('Waiting...') }}

        <v-progress-circular
          size="24"
          indeterminate
          light
          right
        ></v-progress-circular>
      </template>

      <v-icon left>mdi-auto-fix</v-icon>
      {{ $t('Generate') }}
    </v-btn>
    <p class="text-center text-caption black--text ma-1">
      {{ $tc('synthetic_media_tokens_preview', tokensPreview) }}
      {{ $tc('synthetic_media_tokens_remaining', remainingBalance) }}
    </p>

    <SyntheticMediaAdvancedSettings
      @changePromptStrenth="promptStrenthChanged"
      @changeProcessingLevels="processingLevelsChanged"
      @changeDenoisingMethod="denoisingMethodChanged"
      @reset-default-settings="resetDefaultSettings"
      :filter="filter"
      ref="advancedSettings"
    />
  </v-form>
</template>

<style scoped>
  .help-icon {
    margin-top: -2px;
  }
</style>

<script>

import { mapState } from 'vuex'

import QuotaCalculation from '@/utils/quota_calculation'

import SyntheticMediaBarTitle from './SyntheticMediaBarTitle'
import SyntheticMediaFilterSelect from './SyntheticMediaFilterSelect'
import SyntheticMediaNumberOfImagesSelect from './SyntheticMediaNumberOfImagesSelect'
import SyntheticMediaAspectRatioSelect from './SyntheticMediaAspectRatioSelect'
import SyntheticMediaAdvancedSettings from './SyntheticMediaAdvancedSettings'
import SyntheticMediaStyleReference from './SyntheticMediaStyleReference.vue'
import SyntheticMediaImageReference from './SyntheticMediaImageReference.vue'

export default {
  name: 'SyntheticMediaBar',

  components: {
    SyntheticMediaBarTitle,
    SyntheticMediaFilterSelect,
    SyntheticMediaNumberOfImagesSelect,
    SyntheticMediaAspectRatioSelect,
    SyntheticMediaAdvancedSettings,
    SyntheticMediaStyleReference,
    SyntheticMediaImageReference,
  },

  props: {
    loading: Boolean,

    remainingBalance: {
      type: Number,
      default: 0
    },

    styles: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      filter: null,
      prompt: null,
      negativePrompt: null,
      numberOfImages: 1,
      aspectRatio: null,
      promptStrenth: null,
      processingLevels: null,
      denoisingMethod: null,
      imageReference: null,
      imageStrenth: 100,
      referenceProcess: [],
      initializer: -1,
      styleImagesReferences: [],
      styleImagesStrengths: [],
      styleGlobalImageStrength: 50,
      globalStyleLiberty: 50,
    }
  },

  computed: {
    ...mapState({
      generationMessage: state => state.content.synthetic.currentGenerationMessage
    }),

    tokensPreview() {
      if (! this.aspectRatio) {
        return 0
      }

      return QuotaCalculation.calcSynthImageTxt2Img(
        this.denoisingMethod,
        this.processingLevels,
        this.aspectRatio.widthValue,
        this.aspectRatio.heightValue,
        this.numberOfImages,
        this.imageReference ? this.parseReferenceTypes() : []
      )
    },
  },

  methods: {
    filterChanged(filter) {
      this.filter = filter
    },

    numberOfImagesChanged(numberOfImages) {
      this.numberOfImages = numberOfImages
    },

    aspectRatioChanged(aspectRatio) {
      this.aspectRatio = aspectRatio
    },

    promptStrenthChanged(promptStrenth) {
      this.promptStrenth = promptStrenth
    },

    processingLevelsChanged(processingLevels) {
      this.processingLevels = processingLevels
    },

    denoisingMethodChanged(denoisingMethod) {
      this.denoisingMethod = denoisingMethod
    },

    imageReferenceChanged(imageReference) {
      this.imageReference = imageReference
    },

    imageStrengthChanged(imageStrenth) {
      this.imageStrenth = imageStrenth
    },

    referenceProcessChanged(referenceProcess) {
      this.referenceProcess = referenceProcess
    },

    styleImagesReferenceChanged(images) {
      this.styleImagesReferences = images
    },

    styleImagesStrengthChanged(imagesStrength) {
      this.styleImagesStrengths = imagesStrength
    },

    styleGlobalImageStrengthChanged(styleGlobalImageStrength) {
      this.styleGlobalImageStrength = styleGlobalImageStrength
    },

    globalStyleLibertyChanged(globalStyleLiberty) {
      this.globalStyleLiberty = globalStyleLiberty
    },

    generateTxt2Img() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let {width, height} = this.calculateWithAndHeight()

      let data = {
        prompt: this.prompt,
        negative_prompt: this.negativePrompt,
        batch_size: this.numberOfImages,
        cfg_scale: this.promptStrenth,
        steps: this.processingLevels,
        styles: this.parseStyles(),
        sampler_index: this.denoisingMethod,
        width: width,
        height: height,
        seed: this.initializer || -1
      }

      if (this.imageReference) {
        data['reference_image'] = this.imageReference.split(',')[1]
        data['reference_types'] = this.parseReferenceTypes()
        data['reference_weight'] = this.imageStrenth / 100
      }

      if (this.styleImagesReferences.length > 0) {
        data['style_references'] = this.styleImagesReferences.map(image => image.split(',')[1])
        data['global_image_strength'] = this.styleGlobalImageStrength / 100
        data['global_style_liberty'] = this.globalStyleLiberty / 100

        this.styleImagesStrengths.forEach((value, index) => {
          let formattedValue = value / 100

          if (typeof this.styleImagesReferences[index] === 'undefined') {
            formattedValue = 0
          }

          data[`image${index + 1}_strength`] = formattedValue
        })
      }

      this.$emit('txtToImg', data)
    },

    calculateWithAndHeight() {
      return {width: this.aspectRatio.widthValue, height: this.aspectRatio.heightValue}
    },

    parseStyles() {
      if (this.filter == null) return []

      return [this.filter]
    },

    parseReferenceTypes() {
      if (this.referenceProcess.length == 0) return ['default']

      return this.referenceProcess
    },

    setConfig(config) {
      this.prompt = config.prompt || ""
      this.negativePrompt = config.negative_prompt || ""
      this.initializer = config.seed || -1
      this.$refs.numOfImgSelect.setValue(config.batch_size || 1)
      this.$refs.aspectRatioSelect.setValue(config.aspect_ratio || 0)
      this.$refs.advancedSettings.setPromptStrenth(config.cfg_scale || 7)
      this.$refs.advancedSettings.setProcessingLevels(config.steps || 20)
      this.$refs.advancedSettings.setDenoisingMethod(config.sampler_name || "Euler a")
    },

    setImageReference(imageReference) {
      window.setTimeout(() => this.$refs.imageReferenceSelect.setImageReference(imageReference), 1000)
    },

    setStyleReference(image) {
      window.setTimeout(() => this.$refs.styleReference.setStyleReference(image), 1000)
    },

    resetDefaultSettings() {
      const style = this.styles.find(f => f.enabler_id == this.filter)

      if (! style) {
        return
      }

      this.setConfig(style.enabler_params.default_settings || {})
    },
  }
}
</script>