<template>
  <v-row>
    <v-col cols="8">
      <div class="text-subtitle-2 black--text">
        {{ title }}
        <v-tooltip 
          right
          open-delay="400"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-text="'mdi-help-circle-outline'"
              class="help-icon"
              small
              v-bind="attrs"
              v-on="on"
            ></v-icon>
          </template>
          <span>{{ helpText }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </div>
    </v-col>
    <v-col class="text-right">
      {{ rightText }}
    </v-col>
  </v-row>
</template>

<style scoped>
  .help-icon {
    margin-top: -2px;
  }

  .text-subtitle-2 {
    padding-bottom: 6px;
  }
</style>

<script>

export default {
  name: 'SyntheticMediaBarTitle',

  props: {
    title: String,
    helpText: String,
    rightText: String
  },
}
</script>