<template>
  <div class="settings">
    <v-divider></v-divider>

    <v-expansion-panels
      v-model="panel"
      accordion
      tile
      flat
    >
      <v-expansion-panel outlined>
        <v-expansion-panel-header class="black--text text-uppercase header-panel">
          {{ $t('Advanced settings') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="content-panel">

        <v-slider
          v-model="promptStrenth"
          :label="$t('prompt_strength')"
          :thumb-size="20"
          color="primary"
          thumb-label="always"
          max="30"
          min="1"
          hint="Im a hint"
        >
          <template v-slot:append>
            <v-tooltip right open-delay="400" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="help-icon ma-0 pa-0"
                  small
                  v-bind="attrs"
                  v-on="on"
                >mdi-help-circle-outline</v-icon>
              </template>
              <span>{{$t('prompt_strength_tooltip')}}</span>
            </v-tooltip>
          </template>
        </v-slider>

        <v-slider
          v-model="processingLevels"
          :label="$t('processing_levels')"
          :thumb-size="20"
          color="primary"
          thumb-label="always"
          max="100"
          min="10"
        >
          <template v-slot:append>
            <v-tooltip right open-delay="400" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="help-icon ma-0 pa-0"
                  small
                  v-bind="attrs"
                  v-on="on"
                >mdi-help-circle-outline</v-icon>
              </template>
              <span>{{$t('processing_levels_tooltip')}}</span>
            </v-tooltip>
          </template>
        </v-slider>

        <v-tooltip
          right
          nudge-top="13"
          open-delay="400"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-select
                v-model="denoisingMethod"
                :label="$t('denoising_method')"
                :items="denoisingMethods"
                item-text="title"
                item-value="value"
                outlined
                dense
                v-bind="attrs"
              ></v-select>
            </div>
          </template>
          <span>{{$t('denoising_method_tooltip')}}</span>
        </v-tooltip>

        <v-hover v-slot="{ hover }">
          <v-btn
            @click="$emit('reset-default-settings')"
            :disabled="!filter"
            class="mb-7 text-none white--text"
            color="blue-grey"
            dense
          >
            <v-icon :left="hover" dark>
              mdi-cog-sync
            </v-icon>
            <v-slide-x-transition leave-absolute hide-on-leave>
              <span v-if="hover" class="transition-fast-in-fast-out">{{ $t('reset_default_settings') }}</span>
            </v-slide-x-transition>
          </v-btn>
        </v-hover>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-divider></v-divider>
  </div>
</template>

<style scoped>
  .settings {
    margin-top: 30px;
  }

  .help-icon {
    margin-top: -2px;
  }

  .text-subtitle-2 {
    padding-bottom: 6px;
  }

  .header-panel {
    padding: 0;
  }

  .content-panel {
    margin-top: 20px;
  }
</style>

<style>
  .v-expansion-panel-content.content-panel > .v-expansion-panel-content__wrap {
    padding: 0;
  }
</style>

<script>

import { mapState } from 'vuex'

export default {
  name: 'SyntheticMediaAdvancedSettings',

  props: {
    filter: {
      type: Number,
      default: null
    }
  },

  computed: {
    ...mapState({
      denoisingMethods: state => state.content.synthetic.denoisingMethods
    })
  },

  data() {
    return {
      promptStrenth: 7,
      processingLevels: 20,
      denoisingMethod: null,
      panel: null
    }
  },

  mounted() {
    this.denoisingMethod = this.denoisingMethods[0].value

    this.$emit('changePromptStrenth', this.promptStrenth)
    this.$emit('changeProcessingLevels', this.processingLevels)
    this.$emit('changeDenoisingMethod', this.denoisingMethod)
  },

  watch: {
    promptStrenth() {
      this.$emit('changePromptStrenth', this.promptStrenth)
    },

    processingLevels() {
      this.$emit('changeProcessingLevels', this.processingLevels)
    },

    denoisingMethod() {
      this.$emit('changeDenoisingMethod', this.denoisingMethod)
    },
  },

  methods: {
    setPromptStrenth(promptStrenth) {
      this.promptStrenth = promptStrenth
    },

    setProcessingLevels(processingLevels) {
      this.processingLevels = processingLevels
    },

    setDenoisingMethod(denoisingMethod) {
      this.denoisingMethod = denoisingMethod
    },
  }
}
</script>