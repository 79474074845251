<template>
  <v-slide-group
    v-model="selectedFilterStyle"
    selected-class="selected-filter"
    class="filter-slide"
    show-arrows
  >
    <v-slide-item
      v-for="style in styles"
      :key="style.key"
      v-slot="{ active, toggle }"
      class="pa-1"
    >
      <div class="ma-1">
        <v-badge
          overflow
            left
            overlap
            bordered
            :value="style.tenant_id !== null"
            icon="mdi-star"
          >
          <v-card
            :color="'grey-lighten-1' + (style.enabler_params.title == 'none' ? ' bg-black' : '')"
            :class="active ? 'selected-filter' : null"
            height="83"
            width="83"
            @click="toggle"
            outlined
          >
            <v-row
              v-if="style.enabler_params.title == 'none'"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-icon
                color="white"
                size="40"
                style="margin-top: 22px"
              >mdi-circle-off-outline</v-icon>
            </v-row>
              <v-img
                v-if="style.enabler_params.icon != undefined"
                :src="style.enabler_params.icon">
              </v-img>
          </v-card>
        </v-badge>
        <p class="text-caption" style="margin-top: 4px">
          {{ style.enabler_params.title }}
        </p>
      </div>
    </v-slide-item>
  </v-slide-group>
</template>

<style scoped>
  .bg-black {
    background: black;
  }

  .selected-filter {
    border: solid medium var(--v-primary-base);
    border-radius: 2px;
  }
  .star-ico {
    margin-top: -13px;
    margin-left: -8px;
    z-index: 2;
    color: #fff;
    font-size: 1.4em;
    position: relative;
  }
</style>

<style>
  .filter-slide .v-slide-group__next,
  .filter-slide .v-slide-group__prev {
    min-width: 0;
    margin-top: -30px;
    flex: 20px;
  }
</style>

<script>
export default {
  name: 'SyntheticMediaFilterSelect',

  data() {
    return {
      selectedFilterStyle: -1,
    }
  },

  props: {
    styles: {
      type: Array,
      default: () => []
    }
  },

  created() {
    if (this.selectedFilterStyle >= 0 && this.selectedFilterStyle < this.styles.length) {
      this.$emit('change', this.styles[this.selectedFilterStyle].enabler_id);
    }
  },

  watch: {
    selectedFilterStyle(event) {
      if (event == undefined) {
        this.selectedFilterStyle = null;
        this.$emit('change', null)
        return
      }

      this.selectedFilterStyle = event;
      this.$emit('change', this.styles[event].enabler_id)
    }
  }
}

</script>
