<template>
  <v-card
    :class="{'custom-border': haveImageSelected}"
    outlined
  >
    <v-row
      v-if="! haveImageSelected"
    >
      <v-col
        @click="openUploadModal()"
        class="fill-height text-center d-flex align-center justify-center flex-column upload-card mt-3"
      >
        <v-icon
          class="icon"
          color="defaultIconColor"
          x-large
        >mdi-upload</v-icon>

        <p class="text-wrap-balanced">
          {{ $t('style_reference_upload_message', {qty: maxAllowedUpload}) }}
        </p>
      </v-col>
    </v-row>

    <v-row v-else class="ma-3">
      <v-carousel
        v-model="carousel"
        height="300"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="({}, index) in imageQuantity"
          :key="index"
          :src="selectedImages[index]"
        >
          <div
            v-if="selectedImages[index]"
            class="text-right ma-2"
          >
            <v-btn
              @click="removeSelectedImage(index)"
              class="image-btn text-right ma-2"
              elevation="2"
              fab
              small
            >
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </div>

          <v-card
            v-if="! selectedImages[index]"
            @click="openUploadModal()"
            class="fill-height text-center d-flex align-center justify-center flex-column upload-card mt-3"
            outlined
            light
          >
            <v-icon
              class="icon"
              color="defaultIconColor"
              x-large
            >mdi-upload</v-icon>

            <p class="text-wrap-balanced">
              {{ $t('style_reference_upload_message', {qty: maxAllowedUpload}) }}
            </p>
          </v-card>
        </v-carousel-item>
      </v-carousel>

      <v-card-text class="pa-0 pt-4">
        <SyntheticMediaBarTitle
          :title="$t('image_index_strength', {index: carousel + 1})"
          :helpText="$t('image_strength_tooltip')"
          :rightText="imagesStrength[carousel] + '%'"
        />

        <v-slider
          v-model="imagesStrength[carousel]"
          min="0"
          max="100"
          hide-details
        ></v-slider>
      </v-card-text>
    </v-row>

    <v-divider v-if="haveImageSelected" class="divider-border"></v-divider>

    <v-card
      v-if="haveImageSelected"
      elevation="0"
    >
      <v-card-text class="black--text">
        <SyntheticMediaBarTitle
          :title="$t('global_image_strength')"
          :helpText="$t('image_strength_tooltip')"
          :rightText="globalImageStrength + '%'"
        />

        <v-slider
          v-model="globalImageStrength"
          min="0"
          max="100"
          hide-details
        ></v-slider>

        <SyntheticMediaBarTitle
          :title="$t('global_style_liberty')"
          :helpText="$t('style_liberty_tooltip')"
          :rightText="globalStyleLiberty + '%'"
        />

        <v-slider
          v-model="globalStyleLiberty"
          min="0"
          max="100"
          hide-details
        ></v-slider>
      </v-card-text>
    </v-card>

    <DefaultModal
      @submit="uploadImage"
      :title="$t('file_upload')"
      :submit-button-text="'upload'"
      ref="uploadModal"
    >
      <template v-slot:content>
        <UploadField
          :rules="[
            v => (v && v.length > 0) || $t('form_rules.mandatory'),
            v => (v && v.length <= maxAllowedUpload) || $t('form_rules.max_upload_quantity', {max: maxAllowedUpload})
          ]"
          accept="image/*"
          multiple
        />
      </template>
    </DefaultModal>

  </v-card>
</template>

<style scoped>
  .upload-card {
    cursor: pointer;
  }

  .text-wrap-balanced {
    text-wrap: balance;
  }

  .image-btn {
    background: rgba(0, 0, 0, 0.3) !important;
  }

  .custom-border {
    border-width: medium;
  }

  .divider-border {
    border-width: medium 0 0 0;
  }
</style>

<script>

import DefaultModal from '@/components/DefaultModal.vue'
import UploadField from '@/components/content/default/Form/UploadField.vue'
import SyntheticMediaBarTitle from './SyntheticMediaBarTitle'

export default {
  name: 'SyntheticMediaStyleReference',

  components: {
    DefaultModal,
    UploadField,
    SyntheticMediaBarTitle,
  },

  data() {
    return {
      carousel: 0,
      imageQuantity: 4,
      selectedImages: [],
      imagesStrength: [],
      globalImageStrength: 50,
      globalStyleLiberty: 50,
    }
  },

  watch: {
    selectedImages() {
      this.$emit('changeStyleReferences', this.selectedImages)
    },

    imagesStrength() {
      this.$emit('changeImagesStrength', this.imagesStrength)
    },

    globalImageStrength() {
      this.$emit('changeGlobalImageStrength', this.globalImageStrength)
    },

    globalStyleLiberty() {
      this.$emit('changeGlobalStyleLiberty', this.globalStyleLiberty)
    },
  },

  mounted() {
    this.imagesStrength = Array(this.imageQuantity).fill(100)
  },

  computed: {
    maxAllowedUpload() {
      if (this.selectedImages.length === 0) {
        return this.imageQuantity
      }

      return this.imageQuantity - this.selectedImages.length
    },

    haveImageSelected() {
      return this.selectedImages.length > 0
    }
  },

  methods: {
    openUploadModal() {
      this.$refs.uploadModal.openModal()
    },

    uploadImage(formData) {
      const files = formData.getAll('file')
      let readers = []

      files.forEach(file => {
        readers.push(this.readFileContent(file))
      })

      Promise.all(readers).then(results => {
        this.selectedImages = [...this.selectedImages, ...results]

        this.$refs.uploadModal.resetForm()
        this.$refs.uploadModal.closeModal()
      })
    },

    readFileContent(file) {
      const reader = new FileReader()

      return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
      })
    },

    removeSelectedImage(index) {
      this.selectedImages.splice(index, 1)
    },

    setStyleReference(image) {
      if (this.maxAllowedUpload > 0) {
        this.selectedImages.push(image)

        this.carousel = this.selectedImages.length - 1
      } else {
        this.selectedImages = Object.assign([], this.selectedImages, { [this.carousel]: image })
      }
    },
  }
}
</script>