<template>
  <v-card :class="!selectedImage ? 'card' : ('card selected')" outlined>
    <v-row v-if="!selectedImage">
      <v-col class="text-center col" @click="openUploadModal()">
        <v-icon
          class="icon"
          color="defaultIconColor"
          large
        >mdi-upload</v-icon>
        <p
          class="text-caption"
        >
          {{ $t('upload_an_image') }}<br/>
          {{ $t('to_use_as_input') }}
        </p>
      </v-col>

      <v-divider class="divider" vertical></v-divider>

      <v-col class="text-center col" @click="reference3dDialog = true">
        <v-icon
          class="icon"
          color="primary"
          large
        >mdi-cube</v-icon>

        <p
          class="text-caption primary--text"
        >
          {{ $t('create_3D_image') }}<br/>
          {{ $t('to_use_as_reference') }}
        </p>
      </v-col>
    </v-row>

    <v-row class="image-row ma-3 selected-image" v-if="selectedImage">
      <v-img
        :src="selectedImage"
        width="100%"
      >
        <div class="text-right ma-2">
          <v-btn
            @click="reference3dDialog = true"
            icon
            elevation="2"
            class="image-btn mr-2"
          >
            <v-icon color="white">mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            @click="selectedImage = null; imageStrenth = 100; activeReferences = ['default'];"
            icon
            elevation="2"
            class="image-btn"
          >
            <v-icon color="white">mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-img>

      <SyntheticMediaBarTitle
        class="image-title"
        :title="$t('Image strength')"
        :helpText="$t('image_strength_tooltip')"
        :rightText="imageStrenth + '%'"
      />

      <div style="width:100%;display:block">
        <v-slider
          v-model="imageStrenth"
          min="0"
          max="100"
          hide-details
        ></v-slider>
      </div>

      <SyntheticMediaBarTitle
        class="image-title"
        :title="$t('Reference process')"
        :helpText="$t('reference_process_tooltip')"
      />

      <v-row class="references-cols">
        <v-col
          v-for="reference in references"
          :key="reference"
          cols="3"
        >
          <v-card
            :color="'grey-lighten-1' + (reference == 'default' ? ' bg-black' : '')"
            :class="isReferenceActive(reference) ? 'selected-reference' : null"
            height="78"
            width="78"
            @click="toggleActiveReference(reference)"
            outlined
          >
            <v-row
              v-if="reference == 'default'"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-icon
                color="white"
                style="margin-top: 22px"
              >mdi-circle-off-outline</v-icon>
            </v-row>

            <v-img
              v-if="reference != 'default'"
              :src="require(`@/assets/content/synthetic/reference_process/${reference}.jpg`)"
              size="40"
              height="100%"
            >
            </v-img>
          </v-card>
          <p class="text-caption" style="margin-top: 4px">
            {{ reference.charAt(0).toUpperCase() + reference.slice(1).split("-").join(" ") }}
          </p>
        </v-col>
      </v-row>
    </v-row>

    <DefaultModal
      :title="$t('file_upload')"
      :submit-button-text="'upload'"
      @submit="uploadImage"
      ref="uploadModal"
    >
      <template v-slot:content>
        <UploadField
          :rules="[v => (v && v.length > 0) || $t('form_rules.mandatory')]"
          :multiple="false"
          accept="image/*"
        />
      </template>
    </DefaultModal>

    <v-dialog
      v-model="reference3dDialog"
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
      scrollable
    >
      <Reference3D
        :jsm-node-id="parseInt(this.$route.params.nodeId)"
        @submit-image="set3dReferenceImage"
        @close="reference3dDialog = false"
      />
    </v-dialog>

  </v-card>
</template>

<style scoped>
  .card {
    min-height: 140px;
    margin-top: 10px;
  }

  .card.selected {
    border: solid 2px var(--v-primary-base);
  }

  .icon {
    margin-bottom: 5px;
  }

  .divider {
    height: 98px;
    margin-top: 34px;
  }

  .col {
    padding: 40px 10px 0 10px;
    cursor: pointer;
  }

  .image-row {
    margin: 0;
  }

  .image-btn {
    background: rgba(0, 0, 0, 0.7);
  }

  .image-title {
    margin-top: 5px;
  }

  .bg-black {
    background: black;
  }

  .selected-reference {
    border: solid 4px var(--v-primary-base);
    border-radius: 2px;
  }

  .references-cols {
    margin-top: -24px;
  }
</style>

<style>
  .selected-image .v-slider--horizontal {
    margin-left: 0;
    margin-right: 0;
  }
</style>

<script>

import DefaultModal from '@/components/DefaultModal.vue'
import UploadField from '@/components/content/default/Form/UploadField.vue'
import SyntheticMediaBarTitle from './SyntheticMediaBarTitle'
import Reference3D from '@/components/content/synthetic/reference_3d/Reference3D.vue'

export default {
  name: 'SyntheticMediaImageReference',

  components: {
    DefaultModal,
    UploadField,
    SyntheticMediaBarTitle,
    Reference3D
  },

  data() {
    return {
      selectedImage: null,
      imageStrenth: 100,
      references: ['default', 'depth', 'edge', 'pose'],
      activeReferences: ['default'],
      reference3dDialog: false
    }
  },

  watch: {
    selectedImage() {
      this.$emit('changeImageReference', this.selectedImage)
    },

    imageStrenth() {
      this.$emit('changeImageStrength', this.imageStrenth)
    },

    activeReferences() {
      this.$emit('changeReferenceProcess', this.activeReferences)
    }
  },

  methods: {
    openUploadModal() {
      this.$refs.uploadModal.openModal()
    },

    uploadImage(formData) {
      let file = formData.get('file')
      let reader = new FileReader()

      reader.onload = (event) => {
        this.selectedImage = event.target.result
        this.$refs.uploadModal.resetForm()
        this.$refs.uploadModal.closeModal()
      }

      reader.readAsDataURL(file)
    },

    set3dReferenceImage(imageData) {
      this.selectedImage = imageData

      this.reference3dDialog = false
    },

    setImageReference(imageReference) {
      this.selectedImage = imageReference
    },

    isReferenceActive(reference) {
      return this.activeReferences.indexOf(reference) > -1
    },

    toggleActiveReference(reference) {
      if (reference == 'default') {
        if (this.activeReferences.indexOf('default') == -1) {
          this.activeReferences = ['default']
        }

        return
      }

      if (this.activeReferences.indexOf('default') > -1) {
        this.activeReferences = this.activeReferences.filter(e => e !== 'default')
      }

      if (this.activeReferences.indexOf(reference) == -1) {
        this.activeReferences.push(reference)
        return
      }

      this.activeReferences = this.activeReferences.filter(e => e !== reference)

      if (this.activeReferences.length == 0) {
        this.activeReferences = ['default']
      }
    }
  }
}
</script>