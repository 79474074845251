<template>
  <div class="container">
    <v-row
      v-for="(item, idx) in filteredIncompleteHistoricalGeneration"
      :key="idx"
    >
      <v-col cols="12" class="text-truncated">
        <h3 class="caption">
          {{ item[0].referenced_node.system_fields.prompt }}
          <v-icon @click="details(item)" small>mdi-dots-horizontal-circle-outline</v-icon>
        </h3>
      </v-col>

      <v-row class="carousel">
        <v-col
          cols="3"
          v-for="img in item"
          :key="img.to_node_id"
        >
          <v-img
            class="rounded"
            :src="img.preview"
            :ref="'img' + img.to_node_id"
            @load="cacheImage(img.to_node_id)"
            @click="openPreviewModal(img)"
            eager
          ></v-img>
        </v-col>
      </v-row>

    </v-row>

    <v-dialog max-width="600" v-model="detailsModal">
      <v-card class="dialog-card">
        <v-card-text>
          <h4 class="caption black--text" style="padding-top:10px">{{ $t('Prompt') }}</h4>

          <v-sheet class="prompt-box gray--text" outlined rounded>
            {{ selectedData.prompt }}
          </v-sheet>

          <h4 class="caption black--text">{{ $t('Negative prompt') }}</h4>

          <v-sheet class="prompt-box gray--text" outlined rounded>
            {{ selectedData.negative_prompt }}
          </v-sheet>

          <v-row style="margin-top:10px">
            <v-col cols="6">
              <h4 class="caption gray--text">{{ $t('Filter style') }}</h4>
              <p class="black--text">
                {{ selectedData.styles }}
              </p>

              <h4 class="caption gray--text">{{ $t('Number of images') }}</h4>
              <p class="black--text">
                {{ selectedData.batch_size }}
              </p>

              <h4 class="caption gray--text">{{ $t('Aspect ratio') }}</h4>
              <p class="black--text">
                {{ $t(aspectRatio(selectedData.width, selectedData.height)) }}
              </p>

              <h4 class="caption gray--text">{{ $t('prompt_strength') }}</h4>
              <p class="black--text">
                {{ selectedData.cfg_scale }}
              </p>

              <h4 class="caption gray--text">{{ $t('processing_levels') }}</h4>
              <p class="black--text">
                {{ selectedData.steps }}
              </p>

              <h4 class="caption gray--text">{{ $t('Initializer') }}</h4>
              <p class="black--text">
                {{ selectedData.seed }}
              </p>
            </v-col>
            <v-col cols="6">
              <h4 class="caption gray--text">{{ $t('Created') }}</h4>
              <p class="black--text">
                {{ formatDate(selectedData.job_timestamp) }}
              </p>

              <h4 class="caption gray--text">{{ $t('Cost') }}</h4>
              <p class="black--text">
                {{ selectedData.jsm_cost || '-' }}
              </p>

              <h4 class="caption gray--text">{{ $t('denoising_method') }}</h4>
              <p class="black--text">
                {{ selectedData.sampler_name }}
              </p>

              <h4 class="caption gray--text">{{ $t('Image strength') }}</h4>
              <p class="black--text">
                {{ selectedData.reference_weight || '-' }}
              </p>

              <h4 class="caption gray--text">{{ $t('Reference process') }}</h4>
              <p class="black--text">
                {{ selectedData.reference_types || '-' }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="reuseConfig">{{ $t('Reuse configurations') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewDialog"
      :max-width="previewMaxWidth"
      max-height="820"
    >
      <v-card class="preview-card" dark>
        <v-card-text style="padding-top: 20px">
          <v-row align="center" justify="center" style="margin-top:0">
            <v-img
              :src="selectedImageData"
              :width="this.selectedImage ? this.selectedImage.referenced_node.system_fields.width : null"
              :height="this.selectedImage ? this.selectedImage.referenced_node.system_fields.height : null"
              max-width="previewImageMaxWidth"
              max-height="768"
              class="mb-4 rounded"
            ></v-img>
          </v-row>

          <v-row align="center" justify="center" style="margin-bottom: 0">
            <v-btn-toggle dark>
              <v-btn @click="openUpscaleModal" icon><v-icon color="#A4A4A4">mdi-resize</v-icon></v-btn>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  ><v-icon
                    color="#A4A4A4"
                  >mdi-content-duplicate</v-icon
                  >{{ $t('use_image_in') }}
                  </v-btn>
                </template>

                <v-list dark dense class="">
                  <v-list-item
                    v-for="(item, index) in useImageAsTypes"
                    :key="index"
                  >
                    <v-btn
                      @click="useAsReference(item.eventToEmit)"
                      class="text-none"
                      text
                    >{{ $t(item.title) }}</v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn @click="download" icon><v-icon color="#A4A4A4">mdi-download</v-icon></v-btn>
              <v-btn @click="deleteImage" icon><v-icon color="#A4A4A4">mdi-delete</v-icon></v-btn>
            </v-btn-toggle>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DefaultModal
      :title="$t('upscale')"
      :submit-button-text="'upscale'"
      @submit="upscale"
      ref="upscaleModal"
    >
      <template v-slot:content>

        <v-radio-group
          v-model="upscaleFactor"
          :label="$t('upscale_factor')"
          class="ma-0"
          mandatory
        >
          <v-radio
            v-for="item in upscaleOptions"
            :key="item.value"
            :label="item.title"
            :value="item.value"
          ></v-radio>
        </v-radio-group>

        <p class="text-center black--text ma-0">
          {{ $tc('synthetic_media_tokens_preview', upscaleCost) }}
          {{ $tc('synthetic_media_tokens_remaining', remainingBalance) }}
        </p>

      </template>
    </DefaultModal>

  </div>
</template>

<style scoped>
  .container {
    padding: 0 20px;
  }

  .carousel {
    margin-bottom: 10px;
  }

  .prompt-box {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .dialog-card {
    padding: 10px;
  }

</style>

<script>

import HumanReadable from "@/utils/human_readable"
import QuotaCalculation from '@/utils/quota_calculation'

import DefaultModal from '@/components/DefaultModal.vue'

export default {
  name: 'SyntheticMediaHistorical',

  props: {
    historical: Object,

    remainingBalance: {
      type: Number,
      default: 0
    },
  },

  components: {
    DefaultModal
  },

  data() {
    return {
      imgCache: [],
      selectedData: {},
      detailsModal: null,
      previewDialog: false,
      selectedImage: null,
      selectedImageData: null,
      upscaleFactor: 2.5,
      upscaleOptions: [
        {value: 2.5, title: '2.5x'},
        {value: 5, title: '5x'}
      ],
      useImageAsTypes: [
        {title: 'style_reference', eventToEmit: 'use-as-style-reference'},
        {title: 'image_reference', eventToEmit: 'use-as-image-reference'},
      ],
    }
  },

  computed: {
    filteredIncompleteHistoricalGeneration() {
      return Object.keys(this.historical).filter((key) => {
        return parseInt(this.historical[key][0].referenced_node.system_fields.batch_size, 10) == this.historical[key].length
      })
      .reduce((obj, key) => {
        obj[key] = this.historical[key]

        return obj
      }, {})
    },

    previewMaxWidth() {
      if (!this.selectedImage) {
        return 820
      }

      let width = this.selectedImage.referenced_node.system_fields.width
      let height = this.selectedImage.referenced_node.system_fields.height

      if (this.aspectRatio(width, height) == 'Tall') {
        return 550
      }

      return 820
    },

    previewImageMaxWidth() {
      if (!this.selectedImage) {
        return 768
      }

      let width = this.selectedImage.referenced_node.system_fields.width
      let height = this.selectedImage.referenced_node.system_fields.height

      if (this.aspectRatio(width, height) == 'Tall') {
        return 500
      }

      return 768
    },

    upscaleCost() {
      if (!this.selectedImage) {
        return 0
      }

      const systemFields = this.selectedImage.referenced_node.system_fields ?? []

      return QuotaCalculation.calcSynthImageUpscale(
        this.upscaleFactor,
        systemFields.sampler_name,
        systemFields.steps,
        systemFields.width,
        systemFields.height
      )
    },
  },

  methods: {
    cacheImage(nodeId) {
      fetch(this.$refs['img' + nodeId][0].src)
        .then((result) => result.blob())
        .then((blob) => {
          let reader = new FileReader()

          reader.onload = (event) => {
            if (!this.imgCache.find(img => img.nodeId == nodeId)) {
              this.imgCache.push({nodeId: nodeId, data: event.target.result})
            }
          }

          reader.readAsDataURL(blob)
        })
    },

    details(image) {
      this.selectedData = image[0].referenced_node.system_fields
      this.detailsModal = true
    },

    aspectRatio(width, height) {
      if (width > height) {
        return 'Wide'
      }

      if (width < height) {
        return 'Tall'
      }

      return 'Square'
    },

    formatDate(timestamp) {
      return HumanReadable.epochTimestampToDate(timestamp, true)
    },

    reuseConfig() {
      let selectedData = this.selectedData
      selectedData.aspect_ratio = this.aspectRatio(selectedData.width, selectedData.height).toLowerCase()
      this.$emit('set-config', selectedData)
      this.detailsModal = false
    },

    openPreviewModal(image) {
      this.selectedImage = image
      this.selectedImageData = this.imgCache.find(img => img.nodeId == image.to_node_id).data

      this.previewDialog = true
    },

    openUpscaleModal() {
      this.$refs.upscaleModal.openModal()
    },

    upscale() {
      const data = {
        nodeId: this.selectedImage.to_node_id,
        upscale: this.upscaleFactor,
        cost: this.upscaleCost
      }

      this.$emit('generate-upscale', data)

      this.$refs.upscaleModal.closeModal()

      this.previewDialog = false
    },

    useAsReference(eventName) {
      this.$emit(eventName, this.selectedImageData)
      this.previewDialog = false
    },

    download() {
      let a = document.createElement("a")
      a.href = this.selectedImageData
      a.download = this.selectedImage.referenced_node.name
      a.click()
    },

    deleteImage() {
      this.$emit('delete-image', this.selectedImage.to_node_id)
      this.previewDialog = false
    }
  }
}
</script>