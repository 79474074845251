<template>
  <v-dialog
    v-model="loading"
    :hide-overlay="hideOverlay"
    transition="dialog-top-transition"
    content-class="loading-model-dialog"
    max-width="400"
    persistent
  >
    <v-card
      class="pa-md-6 d-flex flex-column overflow-y-auto"
      max-height="200"
    >
      <v-row
        v-for="(item, index) in loadingList"
        :key="index"
        class="mb-4"
        no-gutters
      >
        <v-col md="12">
          <v-progress-linear
            :value="item.progress"
            height="20"
            rounded
            striped
          >
            <template v-slot:default="{ value }">
              {{ `${item.name} ${Math.ceil(value)}%` }}
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style scoped>
  >>>.loading-model-dialog {
    margin-top: 5%;
    align-self: flex-start;
  }
</style>

<script>
export default {
  name: "ModelLoadProgress",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    loadingList: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
