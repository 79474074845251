const PIXEL_AREA_COMPENSATION = 0.000001526

const SAMPLER_COST = JSON.parse(process.env.VUE_APP_SYNTHETIC_QUOTA_SAMPLER_COST)
const REFERENCE_COST = JSON.parse(process.env.VUE_APP_SYNTHETIC_QUOTA_REFERENCE_COST)

export default {
  calcSynthImageTxt2Img(samplerName, steps, width, height, batch, references) {
    const aspectRatio = this.getAspectRatioCost(width, height)
    const sampler = this.getSamplerCostByName(samplerName)
    const referencesCost = this.getReferenceCost(references)

    steps = this.atLeastOneConstraint(steps)
    batch = this.atLeastOneConstraint(batch)

    let subtotal = sampler * steps * aspectRatio
    subtotal += subtotal * referencesCost

    return Math.round((subtotal * batch) / 10)
  },

  calcSynthImageUpscale(upscale, sampler, steps, width, height) {
    const txt2imgCost = this.calcSynthImageTxt2Img(sampler, steps, width, height, 1, [])

    if (upscale == 2.5) {
        return Math.round(txt2imgCost * 5)
    }

    return Math.round(txt2imgCost * 10)
  },

  getSamplerCostByName(samplerName) {
    return SAMPLER_COST[samplerName] ?? 1
  },

  getReferenceCost(references) {
    if (!references) {
      return 0
    }

    const selectedValues = Object.keys(REFERENCE_COST).map(key => {
      if (references.includes(key) ) {
        return REFERENCE_COST[key]
      }

      return 0
    })

    return selectedValues.reduce((partialSum, singleRefCost) => partialSum + singleRefCost, 0)
  },

  getAspectRatioCost(width, height) {
    return (width * height) * PIXEL_AREA_COMPENSATION
  },

  atLeastOneConstraint(value) {
    if (value < 1) {
      return 1
    }

    return Math.round(value)
  }
}
