<template>
  <v-card
    :loading="loading"
    class="mx-auto"
    flat
  >
    <v-container class="p-0" fluid>
      <v-text-field
        v-model="search"
        :disabled="loading || nodeList.length === 0"
        :label="$t('search')"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
        clearable
      ></v-text-field>

      <v-row
        v-if="loading || nodeList.length === 0"
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          <span v-if="loading">{{ $t('retrieving_files') }}</span>

          <span v-else>{{ $t('empty_library') }}</span>
        </v-col>
      </v-row>

      <v-row
        v-else
        class="mb-6"
        dense
      >
        <v-col
          v-for="node in filteredList"
          :key="node.nodeId"
          class="d-flex child-flex"
          cols="4"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              outlined
            >
              <v-img
                :src="getThumbUrl(node)"
                class="d-flex justify-start align-start grey"
                gradient="to top, rgba(0, 0, 0, 0.7) 0%, transparent 52px"
                aspect-ratio="1"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-icon
                      large
                    >mdi-cube</v-icon>
                  </v-row>
                  
                </template>
                
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :class="hover ? '' : 'not-visible'"
                        :disabled="disableImport"
                        @click="createDownloadLink(node)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        dark
                      >
                        <v-icon>mdi-import</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('import_model') }}</span>
                  </v-tooltip>
                </v-card-actions>

                <v-card-actions>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="white--text text-truncate"
                      >{{ node.name }}</span>
                    </template>
                    <span>{{ node.name }}</span>
                  </v-tooltip>
                </v-card-actions>

              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

  </v-card>
</template>

<style scoped>
  .bottom-gradient {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 72px);
  }

  .not-visible {
    visibility: hidden;
  }
</style>

<script>

import {mapGetters, mapActions} from 'vuex'

import SyntheticActions from '@/store/content/synthetic/actions-types'

import api from '@/services/api'

export default {
  name: "ReferenceLibrary",

  props: {
    jsmNodeId: {
      type: Number,
      required: true,
    },
    disableImport: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      search: '',
      nodeList: [],
    }
  },

  mounted() {
    this.fetchData()
  },

  computed: {
    ...mapGetters('app', [
      'getSelectedContentAppAccessUrl',
    ]),

    filteredList() {
      if (! this.search) {
        return this.nodeList
      }

      return this.nodeList.filter(node => {
        return node.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },

  methods:{
    ...mapActions('content/synthetic', [
      SyntheticActions.GET_3D_LIBRARY_LIST,
    ]),

    async fetchData() {
      this.loading = true

      this[SyntheticActions.GET_3D_LIBRARY_LIST](this.jsmNodeId)
        .then((response) => {
          this.nodeList = [...response]

          this.loading = false
        })
        .finally(() => this.loading = false)
    },

    getThumbUrl(node) {
      let thumbUrl = null

      if (Array.isArray(node.renditions)) {
        thumbUrl = node.renditions[0]
      }

      if (typeof node.renditions === 'object') {
        thumbUrl = Object.values(node.renditions)[0]
      }

      return thumbUrl ?? null
    },

    createDownloadLink(node) {
      const baseUrl = this.getSelectedContentAppAccessUrl()
      const downloadEndpoint = api.content.actions.file.getDownloadEndpoint(node.node_id)

      const downloadLink = `${baseUrl}/${downloadEndpoint}`

      this.$emit('download-link-created', {
        url: downloadLink,
        fileSize: node.filesize,
        name: node.name,
      })
    },
  },
}

</script>
