<template>
  <v-card
    :loading="loading"
    height="100%"
    flat
    tile
  >
    <v-card-title v-if="!loading || loaded" class="card-title">
      <v-icon
        class="title-icon"
      >{{ titleIcon }}</v-icon>
      {{ entryData.name }}
    </v-card-title>

    <v-card-text class="card-text" v-if="!loading || loaded">
      <v-row>
        <v-col cols="3" class="overflow-y-auto columns">
          <SyntheticMediaBar
            :remaining-balance="remainingBalance"
            :loading="generating"
            @txtToImg="txtToImg"
            :styles="styles"
            ref="bar"
          />
        </v-col>

        <v-col
          cols="9"
          ref="historical"
          @scroll="checkLoadMoreHistorical"
          class="overflow-y-auto columns"
        >
          <v-divider class="custom-vertical-divider" vertical></v-divider>

          <SyntheticMediaHistorical
            :historical="historical"
            :remaining-balance="remainingBalance"
            @fetch-historical="fetchHistorical"
            @set-config="setConfig"
            @use-as-style-reference="setStyleReference"
            @use-as-image-reference="setImageReference"
            @generate-upscale="upscale"
            @delete-image="deleteImage"
          />

          <v-btn
            @click="loadFetchHistorical"
            :disabled="loadingHistorical"
            block
            text
          >
            <v-icon left>mdi-reload</v-icon>
            <span class="text-decoration-underline">{{ $t('load_more') }}</span>
          </v-btn>
        </v-col>

      </v-row>
    </v-card-text>

    <v-slide-y-transition>
      <v-btn
        v-if="loadingHistorical"
        :loading="true"
        class="centered"
        fab
        fixed
        bottom
      >
        <v-icon size="30">mdi-reload</v-icon>
      </v-btn>
    </v-slide-y-transition>

    <v-slide-y-transition>
      <v-btn
        v-if="displayBackupBtn"
        @click="$refs.historical.scrollTop = 0"
        class="mr-2"
        fab
        fixed
        right
        bottom
      >
        <v-icon size="30">mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-slide-y-transition>

  </v-card>
</template>

<style scoped>
  .card-title {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .card-text {
    padding: 0 20px;
  }

  .title-icon {
    margin-right: 10px;
    color: var(--v-primary-base);
  }

  .custom-vertical-divider {
    display: block;
    position: absolute;
    min-height: 80vh;
    max-height: 80vh;
  }

  .columns {
    height: 85vh;
  }

  .columns::-webkit-scrollbar {
    display: none;
  }

  .centered {
    left: 65%;
    transform: translate(-65%, 0);
  }
</style>

<script>

import { mapActions, mapState } from 'vuex'

import AppActions from '@/store/app/actions-types'
import EntryActions from '@/store/content/entry/actions-types'
import TenantActions from '@/store/core/tenant/actions-types'
import SyntheticActions from '@/store/content/synthetic/actions-types'
import RenditionActions from '@/store/content/rendition/actions-types'

import SyntheticMediaBar from '@/components/content/synthetic/SyntheticMediaBar'
import SyntheticMediaHistorical from '@/components/content/synthetic/SyntheticMediaHistorical'

const HISTORICAL_TOTAL_SCROLL_PERCENTAGE_CHECK = 1

export default {
  name: 'EditSyntheticMedia',

  components: {
    SyntheticMediaBar,
    SyntheticMediaHistorical
  },

  data() {
    return {
      loading: true,
      loadingHistorical: false,
      loaded: false,
      displayBackupBtn: false,
      entryData: {},
      data: {},
      generating: false,
      historical: [],
      historicalLimit: 30,
      historicalOffset: 0,
      remainingBalance: 0,
      styles: {}
    }
  },

  mounted() {
    this.fetchData()
  },

  computed: {
    ...mapState({
      titleIcon: state => state.content.actions.nodeCardActions.find(action => action.key == 'edit_synthetic_media').icon
    }),
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
      AppActions.OPEN_APP_INFO_MESSAGE
    ]),

    ...mapActions('content/entry', [
      EntryActions.GET_ENTRY_DATA,
      EntryActions.DELETE_ENTRY
    ]),
    ...mapActions('content/synthetic', [
      SyntheticActions.GET_SYNTHETIC_MEDIA,
      SyntheticActions.GENERATE_TXT_TO_IMG,
      SyntheticActions.GET_SYNTHETIC_MEDIA_HISTORICAL,
      SyntheticActions.GENERATE_UPSCALE
    ]),

    ...mapActions('content/rendition', [
      RenditionActions.REMOVE_RENDITION,
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_SERVICE_TENANT_QUOTA,
    ]),

    fetchData() {
      const getEntryData = this[EntryActions.GET_ENTRY_DATA](this.$route.params.nodeId)
      getEntryData.then((result) => {
        this.entryData = result

        this.fetchRemainingBalance()
      })

      const getSyntheticMedia = this[SyntheticActions.GET_SYNTHETIC_MEDIA](this.$route.params.nodeId)
      getSyntheticMedia.then((result) => {
        this.data = result.config || {}

        const itemsStyle = result.styles || {};

        itemsStyle.forEach(item => item.enabler_params = JSON.parse(item.enabler_params))

        const withTenantId = itemsStyle.filter(style => style.tenant_id !== null);
        const withoutTenantId = itemsStyle.filter(style => style.tenant_id === null);

        const sortedWithTenantId = withTenantId.sort((a, b) => {
          return a.tenant_id - b.tenant_id || a.enabler_params.title.localeCompare(b.enabler_params.title);
        });

        this.styles = sortedWithTenantId.concat(withoutTenantId);
      });

      const getHistorical = this.fetchHistorical()

      this.loading = true

      Promise.all([getEntryData, getSyntheticMedia, getHistorical])
        .then(() => {
          this.loading = false
          if (!this.loaded) this.loaded = true
        })
        .catch(() => this.loading = false)
    },

    txtToImg(data) {
      this.generating = true

      this[SyntheticActions.GENERATE_TXT_TO_IMG]({nodeId: this.$route.params.nodeId, params: data})
        .then(() => {
          this.generating = false

          this.fetchLatestHistorical(data)
        })
        .catch(() => this.generating = false)
    },

    fetchRemainingBalance() {
      const requestData = {service: "synthetic_image", tenantId: this.entryData.tenant_id}

      this[TenantActions.GET_SERVICE_TENANT_QUOTA](requestData)
      .then((quota) => {
        this.remainingBalance = quota.balance
      })
    },

    checkLoadMoreHistorical(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target

      this.displayBackupBtn = (scrollTop > clientHeight)

      if (false == this.loadingHistorical && ((scrollTop + clientHeight) >= scrollHeight) * HISTORICAL_TOTAL_SCROLL_PERCENTAGE_CHECK) {
        this.loadFetchHistorical()
      }
    },

    loadFetchHistorical()
    {
      this.loading = true
      this.loadingHistorical = true

      this.fetchHistorical().finally(() => {
        this.loading = false
        this.loadingHistorical = false
      })
    },

    fetchHistorical() {
      const options = {
        limit: this.historicalLimit,
        offset: this.historicalOffset,
        order: 'node_id',
        direction: 'DESC'
      }

      return this[SyntheticActions.GET_SYNTHETIC_MEDIA_HISTORICAL]({nodeId: this.$route.params.nodeId, options})
        .then((result) => {
          let resultCount = 0

          Object.keys(result.items).forEach(function(key) {
            resultCount += result.items[key].length
          })

          if (resultCount == 0) {
            this[AppActions.OPEN_APP_INFO_MESSAGE](this.$t(`no_items_found`))

            return
          }

          this.historicalLimit = result.limit
          this.historicalOffset = this.historicalOffset + resultCount

          this.historical = {...this.historical, ...result.items}
        })
    },

    fetchLatestHistorical(data) {
      const options = {
        limit: data.batch_size,
        offset: 0,
        order: 'node_id',
        direction: 'DESC'
      }

      this.loading = true
      this.loadingHistorical = true

      return this[SyntheticActions.GET_SYNTHETIC_MEDIA_HISTORICAL]({nodeId: this.$route.params.nodeId, options})
        .then((result) => {
          let resultCount = 0

          Object.keys(result.items).forEach(function(key) {
            resultCount += result.items[key].length
          })

          this.historicalOffset = this.historicalOffset + resultCount

          this.historical = {...result.items, ...this.historical}
        })
        .finally(() => {
          this.loading = false
          this.loadingHistorical = false
        })
    },

    setConfig(config) {
      this.$refs.bar.setConfig(config)
    },

    setStyleReference(image) {
      this.$refs.bar.setStyleReference(image)
    },

    setImageReference(image) {
      this.$refs.bar.setImageReference(image)
    },

    upscale(params) {
      this.loading = true
      this[SyntheticActions.GENERATE_UPSCALE](params)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('success_on_generate_upscale'))

          this.remainingBalance -= params.cost || 0

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    deleteImage(nodeId) {
      this.loading = true

      this[RenditionActions.REMOVE_RENDITION](nodeId)
        .then(() => {
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('success_on_delete_image'))
          this.fetchData()
        })
        .catch(() => {
          this.loading = false
        })
    },
  }
}

</script>
