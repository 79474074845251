var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-viewport"},[_c('canvas',{ref:"experience",staticClass:"full-viewport"}),(_vm.showSafeArea)?_c('div',{staticClass:"safe-area",class:_vm.safeAreaOptions[_vm.toggleSafeAreaType]}):_vm._e(),_c('ModelLoadProgress',{attrs:{"loading":_vm.loadingScene || _vm.loadingSingleModel,"hide-overlay":_vm.loadingSingleModel,"loading-list":_vm.loadingModelList}}),_c('v-expansion-panels',{staticClass:"expansion-panels",attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t('object_adjustments')))]),_c('v-expansion-panel-content',[_c('v-select',{attrs:{"label":_vm.$t('selected'),"items":_vm.objectsList,"dense":"","outlined":""},model:{value:(_vm.selectedObjectIndex),callback:function ($$v) {_vm.selectedObjectIndex=$$v},expression:"selectedObjectIndex"}}),_c('span',{staticClass:"text-center d-flex align-center"},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.toggleTransformMode),callback:function ($$v) {_vm.toggleTransformMode=$$v},expression:"toggleTransformMode"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedObject}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cursor-move")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('move_object')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedObject}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-360")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('rotate_object')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedObject}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-resize")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('resize_object')))])])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedObject,"icon":""},on:{"click":_vm.duplicateObject}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('duplicate_object')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedObject,"icon":""},on:{"click":_vm.deleteObject}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('delete_object')))])])],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t('camera_settings')))]),_c('v-expansion-panel-content',[_c('v-slider',{attrs:{"label":_vm.$t('camera_fov'),"max":"90","min":"10"},model:{value:(_vm.cameraFov),callback:function ($$v) {_vm.cameraFov=$$v},expression:"cameraFov"}}),_c('v-checkbox',{staticClass:"m-0",attrs:{"label":_vm.$t('show_safe_area')},model:{value:(_vm.showSafeArea),callback:function ($$v) {_vm.showSafeArea=$$v},expression:"showSafeArea"}}),_c('span',{staticClass:"text-center d-flex align-center"},[_c('v-btn-toggle',{attrs:{"mandatory":"","dense":""},model:{value:(_vm.toggleSafeAreaType),callback:function ($$v) {_vm.toggleSafeAreaType=$$v},expression:"toggleSafeAreaType"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.showSafeArea}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-crop-landscape")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('switch_landscape')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.showSafeArea}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-crop-square")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('switch_square')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.showSafeArea}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-crop-portrait")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('switch_portrait')))])])],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t('scene_light')))]),_c('v-expansion-panel-content',[_c('v-slider',{attrs:{"label":_vm.$t('direction'),"max":"360","min":"-360"},model:{value:(_vm.lightDirection),callback:function ($$v) {_vm.lightDirection=$$v},expression:"lightDirection"}}),_c('v-slider',{attrs:{"label":_vm.$t('strength'),"max":"20","min":"0"},model:{value:(_vm.lightStrength),callback:function ($$v) {_vm.lightStrength=$$v},expression:"lightStrength"}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t('library')))]),_c('v-expansion-panel-content',{staticClass:"library-content"},[_c('ReferenceLibrary',{attrs:{"disable-import":_vm.loadingSingleModel || _vm.loadingScene,"jsm-node-id":_vm.jsmNodeId},on:{"download-link-created":_vm.addObjToScene}})],1)],1)],1),_c('span',{staticClass:"footer-actions-container d-flex justify-center mb-6"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"footer-action",attrs:{"color":"error","fab":"","dark":""},on:{"click":_vm.closeScreen}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-thick")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('close')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"footer-action",attrs:{"color":"success","fab":"","dark":""},on:{"click":_vm.submitScreen}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-bold")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('save_reference')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }