var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings"},[_c('v-divider'),_c('v-expansion-panels',{attrs:{"accordion":"","tile":"","flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{attrs:{"outlined":""}},[_c('v-expansion-panel-header',{staticClass:"black--text text-uppercase header-panel"},[_vm._v(" "+_vm._s(_vm.$t('Advanced settings'))+" ")]),_c('v-expansion-panel-content',{staticClass:"content-panel"},[_c('v-slider',{attrs:{"label":_vm.$t('prompt_strength'),"thumb-size":20,"color":"primary","thumb-label":"always","max":"30","min":"1","hint":"Im a hint"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","open-delay":"400","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"help-icon ma-0 pa-0",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('prompt_strength_tooltip')))])])]},proxy:true}]),model:{value:(_vm.promptStrenth),callback:function ($$v) {_vm.promptStrenth=$$v},expression:"promptStrenth"}}),_c('v-slider',{attrs:{"label":_vm.$t('processing_levels'),"thumb-size":20,"color":"primary","thumb-label":"always","max":"100","min":"10"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","open-delay":"400","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"help-icon ma-0 pa-0",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('processing_levels_tooltip')))])])]},proxy:true}]),model:{value:(_vm.processingLevels),callback:function ($$v) {_vm.processingLevels=$$v},expression:"processingLevels"}}),_c('v-tooltip',{attrs:{"right":"","nudge-top":"13","open-delay":"400","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-select',_vm._b({attrs:{"label":_vm.$t('denoising_method'),"items":_vm.denoisingMethods,"item-text":"title","item-value":"value","outlined":"","dense":""},model:{value:(_vm.denoisingMethod),callback:function ($$v) {_vm.denoisingMethod=$$v},expression:"denoisingMethod"}},'v-select',attrs,false))],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('denoising_method_tooltip')))])]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mb-7 text-none white--text",attrs:{"disabled":!_vm.filter,"color":"blue-grey","dense":""},on:{"click":function($event){return _vm.$emit('reset-default-settings')}}},[_c('v-icon',{attrs:{"left":hover,"dark":""}},[_vm._v(" mdi-cog-sync ")]),_c('v-slide-x-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(hover)?_c('span',{staticClass:"transition-fast-in-fast-out"},[_vm._v(_vm._s(_vm.$t('reset_default_settings')))]):_vm._e()])],1)]}}])})],1)],1)],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }