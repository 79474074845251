<template>
  <v-file-input
    v-model="files"
    :rules="rules"
    :error-messages="errorMessage"
    @click:clear="$emit('clearInput')"
    :multiple="multiple"
    :accept="accept"
    chips
    counter
    show-size
    dense
    outlined
    name="file"
  ></v-file-input>
</template>

<script>

export default {
  name: 'UploadField',

  props: {
    rules: {
      type: Array
    },
    errorMessage: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: true
    },
    accept: String,
  },

  data() {
    return {
      files: []
    }
  },

  methods: {
    getFiles() {
      return this.files
    },

    addFiles(files) {
      this.files = [...this.files, ...files]
    },
  },
}

</script>
