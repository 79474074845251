var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"flat":""}},[_c('v-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"disabled":_vm.loading || _vm.nodeList.length === 0,"label":_vm.$t('search'),"prepend-inner-icon":"mdi-magnify","outlined":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.loading || _vm.nodeList.length === 0)?_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[(_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t('retrieving_files')))]):_c('span',[_vm._v(_vm._s(_vm.$t('empty_library')))])])],1):_c('v-row',{staticClass:"mb-6",attrs:{"dense":""}},_vm._l((_vm.filteredList),function(node){return _c('v-col',{key:node.nodeId,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"outlined":""}},[_c('v-img',{staticClass:"d-flex justify-start align-start grey",attrs:{"src":_vm.getThumbUrl(node),"gradient":"to top, rgba(0, 0, 0, 0.7) 0%, transparent 52px","aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-cube")])],1)]},proxy:true}],null,true)},[_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:hover ? '' : 'not-visible',attrs:{"disabled":_vm.disableImport,"icon":"","dark":""},on:{"click":function($event){return _vm.createDownloadLink(node)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-import")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('import_model')))])])],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"white--text text-truncate"},'span',attrs,false),on),[_vm._v(_vm._s(node.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(node.name))])])],1)],1)],1)]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }