<template>
  <v-slide-group
    v-model="selectedAspectRatio"
    class="slide-group"
    mandatory
  >
    <v-slide-item
      v-for="aspectRatio in aspectsRatio"
      :key="aspectRatio.name"
      v-slot="{ active, toggle }"
    >
      <div>
        <v-card
          :class="'ma-2 card-btn' + (active ? ' active' : '')"
          :style="'margin-top: ' + calculateCardMargin(aspectRatio.height) + 'px !important'"
          :height="aspectRatio.height"
          :width="aspectRatio.width"
          @click="toggle"
          outlined
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
          </v-row>
        </v-card>
        <p class="text-caption text-center" style="margin-top: 4px">
          {{ aspectRatio.name.charAt(0).toUpperCase() + aspectRatio.name.slice(1) }}
        </p>
      </div>
    </v-slide-item>
  </v-slide-group>
</template>

<style scoped>
  .card-btn {
    border: 1px solid #707070;
    background-color: #7070706B;
    padding-top: 18px;
  }

  .card-btn.active {
    border: 1px solid var(--v-primary-darken3);
    background-color: var(--v-primary-lighten1);
  }

  .slide-group {
    margin-bottom: 18px;
  }
</style>

<script>

import { mapState } from 'vuex'

export default {
  name: 'SyntheticMediaAspectRatioSelect',

  data() {
    return {
      selectedAspectRatio: 0
    }
  },

  computed: {
    ...mapState({
      aspectsRatio: state => state.content.synthetic.aspectsRatio
    })
  },

  mounted() {
    this.$emit('change', this.aspectsRatio[this.selectedAspectRatio])
  },

  methods: {
    calculateCardMargin(height) {
      return Math.max(...this.aspectsRatio.map(item => item.height)) - height
    },

    setValue(value) {
      this.selectedAspectRatio = this.aspectsRatio.findIndex((item) => item.name == value)
    }
  },

  watch: {
    selectedAspectRatio() {
      this.$emit('change', this.aspectsRatio[this.selectedAspectRatio])
    }
  }
}

</script>
